import React, { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';

import useT from '@/lib/t';
import FullPage from '@/src/components/UI/FullPage';
import { Button, Flex, useMediaQuery, useSafeLayoutEffect } from '@chakra-ui/react';
import OpenGraph from '@/components/OpenGraph';
import ConditionalFragment from '@/components/common/ConditionalFragment';
import classNames from 'classnames';
import Image from 'next/image';
import FunwooAPI, {
  Agent,
  BuildingShowcaseEntity,
  CountryEnum,
  ListingDetail,
  PageOfUserStory,
  UserStoryShowcaseEntity,
} from '@/swagger';
import Link from 'next/link';
import SplitSwiperSection, { SplitSwiperCard } from '@/src/components/feature/SplitSwiperSection';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import Listing from '@/src/components/UI/Listing';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { LineEventTypes, sendLineTagEvent } from '@/utils/sendLineTagEvent';
import useCollapse from '@/src/hooks/useCollapse';
import { GetStaticProps } from 'next';
import { isSet } from '@/utils/formatChecker';
import { OverseaInvestmentConsultantForm } from '@/src/components/feature/ConsultantForm/ConsultantForm';
import PageTabContainer from '@/src/components/UI/PageTabContainer';
import { BuildingShowcaseCard } from '@/pages/building';

export const QA_DATA = [
  {
    question: '沒有綠卡也能購置美國不動產嗎?',
    answer: '是的，美國沒有限制只有本國人才可以買不動產，外國人買房也沒有額外的外國人買房稅需要負擔。',
  },
  {
    question: '只能選擇網站裡的房源嗎？',
    answer:
      '請直接聯繫 FUNWOO 客服 <a class="underline" href="tel:02-7752-3755">02-7752-3755</a>，或點選此填寫<a class="underline" href="/abroad#contact-us">美國置產購屋需求</a>，我們會提供符合您條件的物件。',
  },
  {
    question: '必須飛美國開戶才能買屋嗎？',
    answer:
      '購置美國不動產無需親自飛抵美國開戶，可以從海外將房款直接打到買賣合約上指定有合法立案的 Escrow 公司 (履約保證信託專戶），通常 1-5 天內 Escrow 會收到匯款並開立收據給買方。',
  },
  {
    question: '如何挑選適合自住 / 投資的物件？',
    answer: 'FUNWOO 依據客戶 6 大條件，安排當地豐富經驗的專業顧問，篩選合適的投資物件供客戶參考？',
    subItems: ['自住或投資的需求', '此次置產的預算', '投資年限', '對租金報酬期待', '對房價漲幅期待', '學區與交通'],
  },
  {
    question: '如果無法親自交屋，如何完成購屋流程？',
    answer: '交屋流程涵蓋三大項目（驗屋、匯款、房屋交付），FUNWOO 當地團隊會協助完成，並及時提供回饋給買家。',
    subItems: [
      '驗屋：FUNWOO 聘請專業有執照驗房師，進行詳盡的屋內、外各項檢測，包含地基、屋頂、水電管線、所有電器、外觀和花園，並提供完整報告給屋主，協助屋主確保屋況皆正常',
      '匯款：款項由買家直接匯款到 Escrow 公司 (履約保證信託專戶），房產顧問不會經手房屋款項',
      '房屋交付：產權過戶完成當天，FUNWOO 房產顧問會親自與賣家或建商會面交付鑰匙及房屋相關說明文件',
    ],
  },
  {
    question: '貸款流程為何？為期多長？',
    answer:
      '美國當地有些銀行可以提供外國人貸款，通常會要求提供相關收入或資產證明， 貸款期間最長可以到 30年。 FUNWOO 也有長期配合的三家銀行可供客戶比較參考。',
  },
  {
    question: '如果沒有時間管理投資物件怎麼辦？',
    answer:
      '為了提供客戶長期可靠的服務，FUNWOO 在美國五個重點城市建立了自己的團隊，提供給客戶一條龍無憂的美國置產服務。我們的專業房產顧問都擁有美國當地專業房地產經紀人執照，都有多年管理物業經驗。 投資標的的選擇上，FUNWOO 會建議各大企業周圍以及租屋需求較高的地點。',
  },
  {
    question: '目前 FUNWOO 服務的美國置產區域有哪些?',
    answer: '目前我們以台灣客戶置產需求最高的五大城市為主：紐約、西雅圖、加州、波士頓以及芝加哥。',
  },
];
const HeroTitleButton: React.FC = () => {
  const t = useT('pages.abroad');

  return (
    <Flex align={'center'} justify={'center'} position={'absolute'} className={'top-0 left-0 w-full h-full z-20'}>
      <Flex alignItems={'center'} flexDirection={'column'}>
        <p
          className={classNames(
            'text-[24px] leading-[42px] sm:text-[24px] sm:leading-[3rem] md:text-[24px]',
            'text-center font-medium uppercase',
            'text-white mb-4 text-shadow'
          )}
        >
          {t('slogan')}
        </p>
        {/*<div className={'w-full px-2'}>*/}
        {/*  <Link href={'/contact'} passHref>*/}
        {/*    <a className={'block py-2 w-full min-w-[13.5rem] text-center font-medium bg-white'}>專人諮詢</a>*/}
        {/*  </Link>*/}
        {/*</div>*/}
        <Link href={'/contact'} passHref>
          <Button
            as={'a'}
            border={0}
            variant='secondary'
            width={{ base: 24 }}
            className={'mb-4 last:mb-0 md:mb-0 md:mr-4 md:last:mr-0'}
            fontWeight={500}
          >
            專人諮詢
          </Button>
        </Link>
      </Flex>
    </Flex>
  );
};

interface Props {
  building: Array<BuildingShowcaseEntity>;
  listings: Array<ListingDetail>;
  agents: Array<Agent>;
  userStories: Array<UserStoryShowcaseEntity>;
}

const Abroad: React.FC<Props> = ({ listings, building, userStories }) => {
  const c = useT();

  return (
    <React.Fragment>
      <OpenGraph
        title={c('og.title')}
        description={'當地執業房產顧問為您量身打造一條龍服務'}
        type='funwoo.abroad'
        url='https://funwoo.com.tw/abroad'
        imageUrl={`${process.env.NEXT_PUBLIC_GOOGLE_CDN_URL}/largedesktop/hero/abroad.webp`}
      />
      <FullPage
        heroWithMask
        title={c('share.nav.abroad')}
        src={'abroad'}
        action={<HeroTitleButton />}
        hasBottomMargin={false}
      >
        <PageTabContainer className={''}>
          <WhyFunwoo data-title={'為何選擇FUNWOO'} />
          <RecommendBuilding data-title={'全新建案'} building={building} />
          <Recommend data-title={'精選房源'} listings={listings} />
          <Services data-title={'服務流程'} />
          <section
            data-title={'講座報名'}
            id={'contact-us'}
            className={'pt-4 bg-gray50'}
            style={{ scrollMarginTop: '66px' }}
          >
            <div className={'bg-black -mx-4 md:mx-auto px-4 sm:px-16 sm:pt-4 pb-8 text-white'}>
              <Heading>講座報名</Heading>
              <OverseaInvestmentConsultantForm />
            </div>
          </section>
          <FAQ data-title={'常見問答'} />
          <RealCase data-title={'客戶故事'} cases={userStories} />
        </PageTabContainer>
      </FullPage>
    </React.Fragment>
  );
};

export default Abroad;

export const getStaticProps: GetStaticProps<Props> = async ({ locale }) => {
  const [
    {
      data: { payload: listings = [] },
    },
    [{ data: americaAgents = [] }, { data: canadaAgents }],
    { data: building },
    { data: userStories },
  ] = await Promise.all([
    FunwooAPI.listingApi.search({ paging: { page: 1, pageSize: 3 }, country: [CountryEnum.US, CountryEnum.CA] }),
    await Promise.all([FunwooAPI.agentApi.findAll({ country: 'US' }), FunwooAPI.agentApi.findAll({ country: 'CA' })]),
    FunwooAPI.buildingApi.getBuildingShowcases({
      countries: [CountryEnum.US, CountryEnum.CA],
    }),
    FunwooAPI.userStory.getShowcases(PageOfUserStory.ABROAD),
  ]);

  const allAgents = americaAgents.concat(canadaAgents);
  const agentMap = new Map<string, Agent>();
  allAgents.forEach((agent) => agentMap.set(agent.sid, agent));

  let selectedAgents = ['tanya', 'vicky', 'shelly'].map((sid) => {
    const agent = agentMap.get(sid)!;
    agentMap.delete(sid);
    return agent;
  });

  const diff = 3 - selectedAgents.length;
  if (diff > 0) {
    selectedAgents = selectedAgents.concat([...agentMap.values()].slice(0, diff));
  }

  return {
    props: {
      building: building.slice(0, 3),
      listings,
      agents: selectedAgents.filter(isSet),
      userStories,
      ...(await serverSideTranslations(locale!, ['common'])),
    },
    revalidate: Number(process.env.NEXT_PUBLIC_REVALIDATE_INTERVAL),
  };
};

const Heading: React.FC = ({ children }) => {
  return <p className={classNames('py-3', 'text-center text-3xl font-medium')}>{children}</p>;
};

const RecommendBuilding = forwardRef<HTMLElement | null, Pick<Props, 'building'>>(({ building, ...props }, ref) => {
  return (
    <section
      {...props}
      id={'recommend-building'}
      ref={ref}
      style={{ scrollMarginTop: '66px' }}
      className={classNames('-mx-4 sm:mx-0 px-4 sm:px-8 pb-8 pt-4 bg-gray50', {
        'hidden invisible': building.length === 0,
      })}
    >
      <Heading>全新建案</Heading>
      <div
        className={classNames(
          'flex flex-col md:flex-row items-start',
          'md:py-4',
          'mb-8 md:-my-4',
          'md:space-x-8',
          'max-w-full',
          'sm:overflow-hidden'
        )}
      >
        {building.map((showcase) => (
          <BuildingShowcaseCard key={showcase.id} className={'w-full'} {...showcase} />
        ))}
      </div>
    </section>
  );
});

const Recommend = forwardRef<
  HTMLElement | null,
  {
    listings: Array<ListingDetail>;
  }
>(({ listings, ...props }, ref) => {
  return (
    <section
      {...props}
      id={'recommend'}
      ref={ref}
      style={{ scrollMarginTop: '66px' }}
      className={'-mx-4 sm:mx-0 px-4 sm:px-8 pb-8 pt-4'}
    >
      <Heading>精選房源</Heading>
      <p className={'pb-4 text-center text-gray700'}>FUNWOO 將依照客戶購屋需求，媒合市場上最佳建案。</p>
      <div className={'flex flex-col md:flex-row items-start mb-8 max-w-full sm:overflow-hidden'}>
        {listings.map((listing) => (
          <div
            key={listing.id}
            className={classNames(
              'sm:flex-1',
              'mb-6 sm:mb-0 last:mb-0 sm:mr-6 sm:last:mr-0',
              'max-w-full w-full md:w-auto',
              'sm:overflow-hidden'
            )}
          >
            <Listing listing={listing} sizes={'100%'} />
          </div>
        ))}
      </div>
      <div className={'flex flex-col sm:flex-row justify-center mx-auto'}>
        <Link href={`/abroad#contact-us`} passHref>
          <Button
            as={'a'}
            variant='secondary'
            w={{ base: '304px', sm: '304px' }}
            alignSelf={'center'}
            display={'block'}
            lineHeight={'24px'}
            className={'mb-4 sm:mb-0 sm:mr-4 py-2 text-center'}
          >
            填寫購屋需求
          </Button>
        </Link>
        <Link
          href={{
            pathname: '/buy',
            query: {
              country: CountryEnum.US,
            },
          }}
          passHref
        >
          <Button
            as={'a'}
            variant='primary'
            w={{ base: '304px', sm: '304px' }}
            alignSelf={'center'}
            display={'block'}
            lineHeight={'24px'}
            className={'py-2 text-center'}
          >
            查看更多房源
          </Button>
        </Link>
      </div>
    </section>
  );
});

const WhyFunwoo = forwardRef<HTMLElement | null>(({ ...props }, ref) => {
  return (
    <section
      {...props}
      id={'why-funwoo'}
      ref={ref}
      style={{ scrollMarginTop: '66px' }}
      className={classNames('mx-auto', 'py-8 sm:px-8', 'w-full sm:w-fit md:w-full')}
    >
      <p className={'mb-8 pb-4 text-xl text-gold text-center'}>當地執業房產顧問為您量身打造一條龍服務</p>
      <div className={classNames('flex', 'flex-col md:flex-row md:items-center')}>
        <div
          className={classNames(
            'flex-1',
            '-mx-4 sm:mx-auto mb-6 md:mr-8 md:mb-0',
            'md:max-w-[29.5rem] md2:max-w-full',
            'w-screen sm:w-full md:w-auto'
          )}
        >
          <Image src={'/abroad/why_funwoo.jpg'} layout={'responsive'} width={3} height={2} />
        </div>
        <div className={'flex-1 sm:px-0'}>
          <p className={'py-3 text-2xl font-medium'}>為何選擇 FUNWOO？</p>
          <ul className={'text-xl text-gray700'}>
            {[
              {
                title: '當地執業房產顧問',
                content:
                  '多年當地服務經驗，隨時提供市場趨勢新訊，包含房價走勢、租金和市場供需分析，為買家做最佳投資建議 。',
              },
              {
                title: '多個城市服務據點',
                content:
                  '服務據點橫跨' +
                  '<b>紐約</b>, <b>西雅圖</b>, <b>貝爾維尤</b>, <b>雷蒙</b>, <b>柯克蘭</b>, <b>北加州</b>, <b>南加州</b>, <b>波士頓</b>和<b>芝加哥</b>。' +
                  '由當地執業顧問依買家個別需求，推薦合適的物件。',
              },
              { title: '兩地服務，一條龍接軌', content: '從貸款、驗房、交屋至出租代管，完整即時服務' },
              {
                title: '移居美國生活說明',
                content: '提供子女夏令營、學區諮詢、當地就業機會和投資資訊，解決移居新城市的種種煩惱',
              },
            ].map(({ title, content }) => (
              <li key={title} className={'relative flex mb-4 last:mb-0 pl-4'}>
                <div className={'absolute w-0.5 h-full left-0 bg-gray500'}></div>
                <div className={'text-gray700'}>
                  <p className={'text-lg font-medium'}>{title}</p>
                  <p className={'text-base'} dangerouslySetInnerHTML={{ __html: content }} />
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
});

const Services = forwardRef<HTMLElement | null>(({ ...props }, ref) => {
  const [isMounted, setIsMounted] = useState<boolean>(false);
  const [node, setNode] = useState<HTMLElement | null>(null);

  const [match] = useMediaQuery('(min-width: 768px)');

  useSafeLayoutEffect(() => {
    setIsMounted(true);
  }, []);

  const { height, triggerCollapsing, isCollapsed } = useCollapse({ collapsibleContainer: node }, [isMounted]);

  return (
    <section
      {...props}
      id={'service-process'}
      ref={ref}
      style={{ scrollMarginTop: '66px' }}
      className={'-mx-4 sm:mx-auto pt-4 px-4 sm:px-8 pb-16 bg-gray50'}
    >
      <Heading>全面性服務流程</Heading>
      <div className={'sm:flex sm:items-start sm:justify-center sm:mx-auto sm:max-w-[56.75rem]'}>
        <div className={'sm:flex-1 sm:mr-12'}>
          {[
            {
              title: '瞭解購屋需求',
              content:
                '深入了解買家的購屋條件（目的、區域和預算），共同建立明確的購屋策略和目標，確保有效完成購屋流程。',
            },
            {
              title: '推薦貸款銀行',
              content: '依照客戶目前的資產規劃方式，推薦專業的海外貸款合作夥伴，協助台灣買家在美國貸款買屋。',
            },
            {
              title: '媒合市場物件',
              content: 'FUNWOO 於當地的專業顧問會針對預設的購屋條件、市場與環境狀況，推薦合適的物件。',
            },
            {
              title: '製作物件分析報告',
              content:
                '根據買家欲購買的房屋和附近正在出售及已成交的類似物件，透過 AI 工具比對分析數據，幫助買家合理出價。',
            },
          ].map(({ title, content }, index, array) => (
            <div key={title} className={'relative flex'}>
              <div className={'absolute left-[15px] w-0.5 flex flex-col h-full'}>
                <div className={'flex-1 w-full bg-black'} />
                <ConditionalFragment condition={isCollapsed}>
                  <div
                    className={classNames('flex flex-col', 'h-16', {
                      hidden: index !== array.length - 1,
                    })}
                  >
                    {[...new Array(17)].map((_, index) => (
                      <div
                        key={index}
                        className={classNames({
                          'flex-[2]': index !== 0 && index !== 16,
                          'flex-1': index === 0 || index === 16,
                          'bg-black': index % 2 === 0,
                        })}
                      />
                    ))}
                  </div>
                </ConditionalFragment>
              </div>
              <div className={'relative mr-6 z-10'}>
                <div
                  className={
                    'flex items-center justify-center w-8 h-8 text-lg text-white font-medium bg-black flex-shrink-0 rounded-[999px]'
                  }
                >
                  {index + 1}
                </div>
              </div>
              <div className={'h-40'}>
                <p className={'mb-2 text-xl leading-[2rem] font-medium'}>{title}</p>
                <p className={'text-gray700'}>{content}</p>
              </div>
            </div>
          ))}
        </div>
        <div
          ref={setNode}
          style={{ height: match && isMounted ? undefined : height }}
          className={classNames('sm:flex-1 transition-[height] duration-300 overflow-hidden')}
        >
          <div>
            {[
              {
                title: '視訊看屋',
                content:
                  'FUNWOO 當地房產顧問會提供視訊看房服務，讓遠在台灣的買家檢視屋內各個空間細節以及周邊環境，並即時回覆所有問題。',
              },
              {
                title: '提供驗屋服務',
                content:
                  '聘請專業驗屋師在交屋前為房子做詳細檢查，包括房屋基本結構、水電瓦斯空調及有無漏水狀況，評估後若需維修，驗屋師會提供詳盡的驗屋報告。',
              },
              {
                title: '交屋',
                content:
                  '買房將款項存進履保帳⼾ (Escrow)，產權公司 (Title company) 協助將產權過戶至買家名下，正式完成交屋。',
              },
              {
                title: '提供出租管理服務',
                content:
                  '針對海外投資的買家，FUNWOO 提供全面性的出租管理服務，協助挑選租客，且隨時支援房屋所需要的維修，省去買家購屋後的煩惱。',
              },
            ].map(({ title, content }, index, array) => (
              <div key={title} className={'relative flex'}>
                <div
                  className={classNames('absolute left-[15px] w-0.5 h-full bg-black', {
                    hidden: index === array.length - 1,
                  })}
                />
                <div className={'relative mr-6 z-10'}>
                  <div
                    className={
                      'flex items-center justify-center w-8 h-8 text-lg text-white font-medium bg-black flex-shrink-0 rounded-[999px]'
                    }
                  >
                    {index + 5}
                  </div>
                </div>
                <div className={'h-40'}>
                  <p className={'mb-2 text-xl leading-[2rem] font-medium'}>{title}</p>
                  <p className={'text-gray700'}>{content}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className={'flex sm:hidden items-center justify-center py-4 w-full'}>
          <button
            type={'button'}
            onClick={() => triggerCollapsing()}
            className={'py-2 w-[13.5rem] border border-solid border-gray900 font-medium'}
          >
            <ConditionalFragment condition={isCollapsed}>查看完整流程</ConditionalFragment>
            <ConditionalFragment condition={!isCollapsed}>隱藏完整流程</ConditionalFragment>
          </button>
        </div>
      </div>
    </section>
  );
});

// const Agents = forwardRef<HTMLElement, Pick<Props, 'agents'>>(({ agents, ...props }, ref) => {
//   return (
//     <section
//       {...props}
//       id={'funwoo-agents'}
//       ref={ref}
//       style={{ scrollMarginTop: '66px' }}
//       className={'sm:px-8 pt-4 pb-16'}
//     >
//       <Heading>美國房產顧問</Heading>
//       <div className={'sm:flex'}>
//         {agents.slice(0, 3).map((agent, index, array) => (
//           <div
//             key={agent.sid}
//             className={classNames('mb-6 last:mb-0 sm:mb-auto sm:ml-8 sm:first:ml-0', 'sm:flex-1 overflow-hidden', {
//               'sm:hidden': index === array.length - 1,
//               'md:block': index === array.length - 1,
//             })}
//           >
//             <AgentCard data={agent} />
//           </div>
//         ))}
//       </div>
//       <Link href={{ pathname: '/agent', query: { country: CountryEnum.US } }} passHref>
//         <a
//           type={'button'}
//           className={'block mx-auto mt-4 py-2 w-[13.5rem] text-center border border-solid border-gray900 font-medium'}
//         >
//           查看所有美國顧問
//         </a>
//       </Link>
//     </section>
//   );
// });

const RealCase = forwardRef<
  HTMLElement | null,
  {
    cases: Array<UserStoryShowcaseEntity>;
  }
>(({ cases, ...props }, ref) => {
  const cards = useMemo<Array<SplitSwiperCard>>(() => {
    return cases.map(({ location, photoUrl, highlights, content, buyer }) => {
      return {
        img: photoUrl,
        city: location ?? '',
        content,
        customer: buyer,
        spotlights: highlights.map(({ label, value }) => ({ content: value, label })),
      };
    });
  }, [cases]);
  return (
    <section {...props} id={'real-case'} ref={ref} style={{ scrollMarginTop: '66px' }}>
      <div className={'bg-black -mx-4 md:mx-auto px-4 pt-4 pb-16'}>
        <p className={'py-4 text-white text-2xl text-center'}>客戶故事</p>
        <SplitSwiperSection cards={cards} />
      </div>
    </section>
  );
});

const FAQ = forwardRef<HTMLElement | null, {}>(({ ...props }, ref) => {
  const [openCardIndex, setOpenCardIndex] = useState<number | null>(null);

  const onCardClick = useCallback((cardIndex: number) => {
    setOpenCardIndex((prev) => (prev === cardIndex ? null : cardIndex));
  }, []);

  return (
    <section
      {...props}
      id={'faq'}
      ref={ref}
      className={'-mx-4 md:mx-auto px-4 py-8 pb-16 bg-gray50'}
      style={{ scrollMarginTop: '66px' }}
    >
      <Heading>常見問答</Heading>
      {QA_DATA.map(({ question, answer, subItems }, index) => (
        <QAItem
          key={question}
          question={question}
          answer={answer}
          subItems={subItems}
          onCardClick={() => onCardClick(index)}
          open={openCardIndex === index}
        />
      ))}
    </section>
  );
});

export const QAItem: React.FC<{
  question: string;
  answer: string;
  subItems?: Array<string>;
  showContactNote?: boolean;
  onCollapseEnd?: (collapsed: boolean) => void;
  onCardClick: () => void;
  open: boolean;
}> = ({ question, answer, subItems, onCollapseEnd, onCardClick, open, showContactNote = true }) => {
  const [node, setNode] = useState<HTMLElement | null>(null);

  const { isCollapsed, triggerCollapsing, height } = useCollapse({ collapsibleContainer: node });

  useEffect(() => {
    triggerCollapsing(!open);
  }, [open]);

  return (
    <div className={'mb-4 last:mb-0 sm:mx-auto max-w-[56.75rem] border border-solid border-gray900'}>
      <button type={'button'} onClick={onCardClick} className={'flex items-center justify-between p-4 w-full'}>
        <p className={'text-gray900 font-medium'}>{question}</p>
        <div className={'flex items-center justify-center w-6 h-6 text-xl'}>
          <ChevronDownIcon
            className={classNames('transition-transform duration-300', {
              'rotate-180': !isCollapsed,
            })}
          />
        </div>
      </button>
      <div
        ref={setNode}
        style={{ height }}
        onTransitionEnd={() => onCollapseEnd?.(isCollapsed)}
        className={'overflow-hidden transition-[height] duration-300'}
      >
        <div className={'px-4 pb-4'}>
          <div
            className={classNames({
              'mb-4': showContactNote,
            })}
          >
            <p className={'text-gray700'} dangerouslySetInnerHTML={{ __html: answer }} />
            <ConditionalFragment condition={isSet(subItems)}>
              <ul className={'mt-1 text-gray700 leading-[1.5rem]'}>
                {subItems?.map((item) => (
                  <li key={item} className={'flex items-start'}>
                    <div className={'h-6 w-6 flex items-center justify-center flex-shrink-0'}>
                      <span className={'w-1 h-1 rounded-[999px] bg-gray700'} />
                    </div>
                    {item}
                  </li>
                ))}
              </ul>
            </ConditionalFragment>
          </div>
          <ConditionalFragment condition={showContactNote}>
            <div className={'py-1 text-brand text-sm'}>
              若有其他相關問題，
              <Link href={'/abroad#contact-us'} passHref>
                <a className={'underline'}>歡迎留訊息聯絡我們</a>
              </Link>
              ，或撥打{' '}
              <a
                className={'underline'}
                rel={'noreferrer noopener'}
                target={'_blank'}
                href={`tel:${process.env.NEXT_PUBLIC_FUNWOO_TAIWAN_CELLPHONE}`}
                onClick={() => sendLineTagEvent(LineEventTypes.phone_call)}
              >
                02-7752-3755
              </a>
            </div>
          </ConditionalFragment>
        </div>
      </div>
    </div>
  );
};
